#colors {
	.color-code {
		width: 13px;
		height: 13px;
		border-radius: 50%;
		overflow: hidden;
		border: 1px solid #828282;
	}
}

#bannerProductAds {
	[class*='swiper-button'] {
		&::after {
			content: none;
		}

		&.swiper-button-prev,
		&.swiper-button-next {
			background-repeat: no-repeat;
			background-size: contain;
			background-position: center;
		}

		&.swiper-button-prev {
			background-image: url(../images/product-ads-prev.svg);
		}

		&.swiper-button-next {
			background-image: url(../images/product-ads-next.svg);
		}
	}
}

#filterRadio {
	#option {
		cursor: pointer;

		input {
			appearance: none;
			display: none;

			&:checked ~ span {
				&::after {
					width: 10px;
					height: 10px;
					background: linear-gradient(to right, #cedd0e 0%, #008c30 100%);
					border-radius: 50%;
					left: 3px;
					top: 50%;
					transform: translateY(-50%);
				}
			}
		}

		span {
			position: relative;

			&::before,
			&::after {
				content: '';
				position: absolute;
			}

			&::before {
				width: 16px;
				height: 16px;
				border: 1px solid #4f4f4f;
				border-radius: 50%;
				left: 0;
				top: 50%;
				transform: translateY(-50%);
			}
		}
	}
}

#priceSlider {
	#slider-range {
		margin-bottom: 20px;
	}

	.ui-widget.ui-widget-content {
		border: unset;
		height: 2px;
		background: #f2f2f2;
	}

	.ui-widget-header {
		border: unset;
		background: $green;
	}

	.ui-slider-horizontal .ui-slider-handle {
		margin-left: -2.5px;
	}

	.ui-state-default,
	.ui-widget-content .ui-state-default {
		width: 10px;
		height: 10px;
		border: unset;
		background: transparent;
		background-image: url(../images/triangle-square.svg);
		background-repeat: no-repeat;
		background-size: contain;

		&:focus,
		&:focus-visible,
		&:focus-within {
			border: unset;
			outline: none;
		}
	}

	.before-amount {
		position: relative;

		&::after {
			content: 'đ';
			display: inline-block;
			width: 0.7rem;
			text-align: right;
		}
	}
}

#imagePreview {
	border: 1px solid #f0f0f0;
	border-radius: 5px;
	padding: 5px;
	margin-bottom: 20px;

	.swiper {
		background: linear-gradient(#fff 0 0) padding-box,
			linear-gradient(to right, #5ca20a 0.29%, #0c6f39 99.88%) border-box;
		border: 2px solid transparent;
		border-radius: 5px;
	}

	.item {
		@include col-img(100%, contain);

		&.video {
			.ic-play {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				object-fit: contain;
				width: 40px;
				height: 40px;
			}
		}
	}
}

#imagePreviewThumbs {
	.swiper-slide {
		cursor: pointer;

		.item {
			@include col-img(100%, cover);
			border-radius: 5px;
			border: 2px solid #eee;

			&.video {
				.ic-play {
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					object-fit: contain;
					width: 18px;
					height: 18px;
				}
			}
		}

		&-thumb-active {
			.item {
				background: linear-gradient(#fff 0 0) padding-box,
					linear-gradient(to bottom, #cddc0e 0.29%, #008c30 99.88%) border-box;
				border: 2px solid transparent;
			}
		}
	}
}

#productDetailQuantity {
	#quantityCounter {
		position: relative;
		margin-top: 0;
		margin-left: 0;
		margin-right: auto;
		max-width: 136px;
		padding: 8px;
		z-index: 1;

		&::before {
			content: '';
			position: absolute;
			background: $green;
			width: 30%;
			height: 75%;
			border-radius: 3px;
			z-index: -1;
		}

		input {
			outline: none;
			border: none;
			background: transparent;
			font-family: 'Inter';
			font-weight: 400;
			font-size: 20px;
			line-height: 1;
			color: #ffffff;
		}
	}
}

#btnDual {
	button {
		display: block;
		border: 1px solid transparent;
		border-radius: 35px;
		transition: all 0.2s;

		&:hover {
			transition: all 0.2s;
		}

		span {
			display: inline-block;
			font-family: 'Inter';
			font-weight: 600;
			font-size: 16px;
			line-height: 1;
			text-align: center;
		}

		@media (max-width: 767px) {
			flex: 1;

			span {
				font-size: 14px;
				padding: 10px;
			}
		}
	}

	#btnFirst {
		background: linear-gradient(#fff 0 0) padding-box,
			linear-gradient(to right, #5ca20a 0.29%, #0c6f39 99.88%) border-box;
		box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.15);

		&:hover {
			background: linear-gradient(
				to right,
				#e5c35c 0%,
				#f9df7b 38.02%,
				#fff3a6 67.19%,
				#f9df7b 99.74%
			);
		}

		span {
			background: linear-gradient(90.24deg, #5ca20a 0.29%, #0c6f39 99.88%);
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
			background-clip: text;
			text-fill-color: transparent;
			padding: 11px 16px;
		}
	}

	#btnSecond {
		background: linear-gradient(90.24deg, #5ca20a 0.29%, #0c6f39 99.88%)
				padding-box,
			linear-gradient(to left, #cedd0e 0%, #008c30 100%) border-box;

		&:hover {
			background: linear-gradient(to left, #cedd0e 0%, #008c30 100%) padding-box,
				linear-gradient(to left, #cedd0e 0%, #008c30 100%) border-box;
		}

		span {
			color: #fff;
			padding: 11px 36px;
		}
	}
}

#bookingSupport {
	background: linear-gradient(to bottom, #5ca20a 2.61%, #0e8242 99.44%)
			padding-box,
		linear-gradient(to bottom, #cedd0e 0%, #008c30 100%) border-box;
	border: 2px solid transparent;
	border-radius: 3px;

	#btn {
		display: inline-block;
		font-family: 'Inter';
		font-style: normal;
		font-weight: 700;
		font-size: 16px;
		line-height: 19px;
		text-align: center;
		color: $green;
		text-transform: uppercase;
		background: linear-gradient(#fff 0 0) padding-box,
			linear-gradient(to bottom, #cedd0e 0%, #008c30 100%) border-box;
		border: 2px solid transparent;
		border-radius: 35px;
		padding: 10px 30px;

		&:hover {
			background: linear-gradient(to top, #cedd0e 0%, #008c30 100%) padding-box,
				linear-gradient(to bottom, #cedd0e 0%, #008c30 100%) border-box;
			color: #fff;
		}
	}
}

#afterSub {
	a {
		position: relative;

		&:not(:last-child) {
			&::after {
				content: '-';
				display: inline-block;
				width: 0.5rem;
				text-align: center;
			}
		}
	}
}

#contentProductDetail {
	position: relative;
}

#tabProductDetail {
	.item {
		display: inline-block;
		font-family: 'Lexend Deca';
		font-weight: 300;
		font-size: 16px;
		line-height: 1;
		color: #bdbdbd;
		border-bottom: 3px solid transparent;
		padding-bottom: 12px;
		transition: unset;

		@media (max-width: 767px) {
			font-size: 14px;
		}

		&.active {
			font-weight: 400;
			transition: unset;
		}

		&.active,
		&:hover {
			color: $green;
			border-bottom: 3px solid currentColor;
			transition: unset;
		}
	}
}

#tabProductDetailContent {
	.item {
		display: none;

		&.active {
			display: block;
		}
	}
}

.boxViewMore {
	display: none;
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	text-align: center;
	z-index: 1;

	&.active {
		display: block;
	}

	&::before {
		content: '';
		position: absolute;
		background: linear-gradient(
			to top,
			#ffffff 23.44%,
			rgba(255, 255, 255, 0) 100%
		);
		width: 100%;
		height: 277px;
		bottom: 0;
		left: 0;
		right: 0;
		z-index: -1;
	}

	#btnLoading {
		display: inline-block;
		font-family: 'Inter';
		font-style: normal;
		font-weight: 400;
		font-size: 12px;
		line-height: 1;
		text-decoration-line: underline;
		color: #4f4f4f;
		padding-bottom: 25px;

		&:hover {
			color: $green;
		}
	}
}

#category,
#colors {
	ul {
		li {
			a {
				&.active {
					color: $green;

					& > * {
						color: $green;
					}
				}
			}
		}
	}
}

#productCateItem {
	position: relative;
	display: flex;
	flex-flow: column;
	height: 100%;

	#productTitle {
		position: relative;
		display: flex;
		flex-flow: column;
		height: 100%;
	}
}
