#list-listStyle {
	& > * {
		position: relative;
		font-family: 'Lexend Deca';
		font-style: normal;
		font-weight: 300;
		font-size: 16px;
		line-height: 24px;
		letter-spacing: 0.01em;
		color: #ffffff;
		padding-left: 1rem;

		@media (min-width: 992px) {
			margin-left: 1rem;
		}

		&::before {
			content: '\2022';
			position: absolute;
			display: inline-block;
			width: 1rem;
			left: 0;
		}
	}
}
