#tableCart {
	@media (max-width: 767px) {
		white-space: nowrap;
		overflow-x: auto;
	}

	table {
		th {
			font-family: 'Lexend Deca';
			font-weight: 400;
			font-size: 14px;
			line-height: 1;
			color: #ffffff;
			background: $green;
			padding: 12px 0;
			padding-left: 15px;

			&:first-child {
				border-top-left-radius: 4px;
				padding-left: 12px;
			}

			&:last-child {
				border-top-right-radius: 4px;
				padding-right: 12px;
			}
		}

		td {
			padding-left: 15px;

			&:first-child {
				padding-left: 0;
			}

			&:last-child {
				padding-right: 12px;
			}
		}
	}
}

#product-model {
	@media (max-width: 767px) {
		white-space: normal;
	}
}

#quantityCounter {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
	max-width: 110px;
	margin-left: auto;
	border: 1px solid #f5f5f5;
	border-radius: 3px;
	padding: 5px;

	@media (min-width: 1200px) {
		margin-top: 8px;
	}

	input {
		flex: 1;
		max-width: calc(100% - 25px);
		font-family: 'Inter';
		font-weight: 400;
		font-size: 15px;
		line-height: 1;
		text-align: center;
		color: #333333;

		&::-webkit-outer-spin-button,
		&::-webkit-inner-spin-button {
			-webkit-appearance: none;
			margin: 0;
		}

		&[type='number'] {
			-moz-appearance: textfield;
		}
	}
}

#btn-payment {
	display: block;
	font-family: 'Inter';
	font-style: normal;
	font-weight: 700;
	font-size: 15px;
	line-height: 1;
	text-align: center;
	text-transform: uppercase;
	color: $green;
	max-width: 230px;
	width: 100%;
	margin: 0 auto;
	background: linear-gradient(
				to right,
				#e5c35c 0%,
				#f9df7b 38.02%,
				#fff3a6 67.19%,
				#f9df7b 99.74%
			)
			padding-box,
		linear-gradient(
				to right,
				#f9df7b 0%,
				#fff3a6 16.26%,
				#f9df7b 27.49%,
				#e5c35c 56.75%
			)
			border-box;
	border: 3px solid transparent;
	outline: 1px solid #008c30;
	outline-offset: -3px;
	border-radius: 200px;
	padding: 11px;

	&:hover {
		background: linear-gradient(
					to right,
					#f9df7b 0%,
					#fff3a6 16.26%,
					#f9df7b 27.49%,
					#e5c35c 56.75%
				)
				padding-box,
			linear-gradient(
					to right,
					#e5c35c 0%,
					#f9df7b 38.02%,
					#fff3a6 67.19%,
					#f9df7b 99.74%
				)
				border-box;
	}
}
