// variables

$gradient-green: linear-gradient(90.24deg, #5ca20a 0.29%, #0c6f39 99.88%);
$green: #008c30;

// set width cho cột cho flex
@mixin col-w($width) {
	-webkit-box-flex: 0;
	-ms-flex: 0 0 $width;
	flex: 0 0 $width;
	max-width: $width;
}

@mixin col-img($pb, $of) {
	position: relative;
	width: 100%;
	padding-bottom: $pb;
	overflow: hidden;
	img {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		object-fit: $of;
		object-position: center;
	}
}

@mixin line-clamp($lc) {
	display: -webkit-box;
	-webkit-line-clamp: $lc;
	-webkit-box-orient: vertical;
	overflow: hidden;
}
