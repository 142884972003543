#header {
	position: relative;
	background: $gradient-green;
	padding: 8px 0;
	z-index: 1;

	&.scroll {
		position: fixed;
		top: 0;
		right: 0;
		left: 0;
		z-index: 11;
	}

	&::before {
		content: '';
		position: absolute;
		width: 100%;
		height: calc(100% + 4px);
		background: linear-gradient(90.24deg, #5ca20a 0.29%, #0c6f39 99.88%);
		opacity: 0.3;
		left: 0;
		right: 0;
		top: 0;
		z-index: -1;
	}

	&-logo {
		img {
			max-width: 145px;
			max-height: 52px;
			width: 100%;
			height: 100%;
			object-fit: scale-down;
		}
	}

	&-search {
		position: relative;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		max-width: 190px;
		background: #ffffff;
		border-radius: 30px;
		padding: 7px 14px;

		@media (min-width: 992px) {
			margin-left: 25px;
		}

		@media (min-width: 1200px) {
			margin-left: 50px;
		}

		@media (max-width: 991px) {
			margin-left: auto;
		}

		@media (max-width: 767px) {
			order: 2;
			width: 100%;
			max-width: 100%;
			margin-top: 8px;
		}

		input {
			flex: 1;
			width: 100%;
			font-family: 'Inter';
			font-style: normal;
			font-weight: 400;
			font-size: 13px;
			line-height: 1;
			color: $green;
			background: transparent;
			border: unset;
		}

		button {
			margin-left: 5px;
		}
	}

	&-cart {
		margin-left: 12px;

		@media (max-width: 767px) {
			margin-left: auto;
		}

		a {
			position: relative;
			display: flex;
			align-items: center;
			background: linear-gradient(to left, #00b112 0.03%, #019a35 99.96%)
					padding-box,
				linear-gradient(to top, #0edd61 0.03%, #008c30 99.96%) border-box;
			border: 2px solid transparent;
			border-radius: 30px;
			padding: 6px 12px;
		}

		span {
			display: inline-block;
			font-family: 'Inter';
			font-weight: 400;
			font-size: 14px;
			line-height: 17px;
			color: #ffffff;
			margin-right: 16px;
		}
	}
}
