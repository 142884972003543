.menu {
	// large screen
	@media (min-width: 992px) {
		&-items {
			position: relative;
			display: flex;
			flex-flow: row;
			height: 100%;
		}

		&-logo {
			order: 1;
			width: 125px;
			height: 125px;

			@media (min-width: 1200px) {
				width: 147px;
				height: 147px;
			}

			img {
				width: 100%;
				height: 100%;
				object-fit: contain;
			}
		}

		&-links {
			position: relative;
			display: flex;
			flex-flow: column;
			justify-content: center;

			&:nth-of-type(n + 5) {
				order: 2;
			}

			&:not(:last-child) {
				margin-right: 20px;

				@media (min-width: 1200px) {
					margin-right: 40px;
				}
			}

			&:hover {
				.menu-sub {
					opacity: 1;
					visibility: visible;
					transform: translateX(-50%) translateY(0);
					transition: all 0.2s;
				}
			}
		}

		&-sub {
			position: absolute;
			top: 100%;
			left: 50%;
			width: max-content;
			opacity: 0;
			visibility: hidden;
			transform: translateX(-50%) translateY(20px);
			transition: all 0.2s;

			&-items {
				background: #ffffff;
				box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
				padding: 15px;
				margin-top: 25px;
			}

			.sub-link {
				position: relative;
				display: block;
				width: 100%;
				font-family: 'Lexend Deca';
				font-style: normal;
				font-weight: 300;
				font-size: 13px;
				line-height: 16px;
				text-transform: capitalize;
				color: $green;

				&:not(:last-child) {
					margin-bottom: 13px;
				}

				&:hover,
				&.active {
					&::before {
						width: 100%;
						transition: all 0.2s;
					}
				}

				&::before {
					content: '';
					position: absolute;
					left: 0;
					bottom: -3px;
					width: 0;
					height: 1px;
					background: $gradient-green;
					transition: all 0.2s;
				}
			}
		}

		.parent-link {
			font-family: 'Lexend Deca';
			font-style: normal;
			font-weight: 500;
			font-size: 14px;
			line-height: 1.25;
			text-align: center;
			color: #ffffff;

			@media (min-width: 1200px) {
				font-size: 16px;
			}

			&:hover,
			&.active {
				background: linear-gradient(
					90deg,
					#e5c35c 0%,
					#f9df7b 38.02%,
					#fff3a6 67.19%,
					#f9df7b 99.74%
				);
				-webkit-background-clip: text;
				-webkit-text-fill-color: transparent;
				background-clip: text;
				text-fill-color: transparent;

				& ~ .icon-nav path {
					fill: #e5c35c;
				}
			}
		}

		.icon-nav {
			display: inline-block;
			vertical-align: baseline;
			margin-left: 4px;
		}

		.svg-inline--fa {
			color: #fff;
			font-size: 10px;
		}
	}

	// mobile screen
	@media (max-width: 991px) {
		.menu-hamburger {
			&-line {
				height: 2px;
				background: white;
				margin: 5px 0 5px auto;

				&:first-child,
				&:last-child {
					width: 25px;
				}

				&:nth-of-type(2) {
					width: 15px;
				}
			}
		}

		&-overlay {
			display: block;
			position: fixed;
			width: 100%;
			height: 100%;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			background: #000;
			opacity: 0;
			visibility: hidden;
			transition: all 0.2s;
			z-index: 111;

			&.active {
				opacity: 0.5;
				visibility: visible;
				transition: all 0.2s;
			}
		}

		&-items {
			display: flex;
			flex-flow: column;
			position: fixed;
			right: 0;
			top: 0;
			bottom: 0;
			background: #fff;
			height: 100%;
			width: 70%;
			transform: translateX(100%);
			transition: all 0.3s ease-in-out;
			z-index: 111;

			&.active {
				transform: translateX(0);
				transition: all 0.3s ease-in-out;
			}

			@media (min-width: 576px) and (max-width: 991px) {
				width: 50%;
			}
		}

		.parent-link {
			font-family: 'Lexend Deca';
			font-weight: 500;
			font-size: 14px;
			line-height: 1.25;
			text-transform: uppercase;
		}

		.sub-link {
			font-family: 'Inter';
			font-weight: 500;
			font-size: 14px;
			line-height: 1.25;
			text-transform: capitalize;
		}

		&-parent {
			display: flex;
			flex-flow: row;
			align-items: center;
			justify-content: space-between;
			padding: 15px 10px;
			border-bottom: 1px solid #ccc;

			.icon-nav {
				font-size: 10px;
				font-weight: 100;
				color: #333;
				transition: all 0.2s;

				&.active {
					transform: rotate(180deg);
					transition: all 0.2s;
				}
			}
		}

		&-sub {
			display: none;

			&-items {
				display: flex;
				flex-flow: column;
				border-bottom: 1px solid #ccc;
				padding: 15px 10px;

				.sub-link {
					&:not(:last-child) {
						margin-bottom: 10px;
					}
				}

				.icon-nav {
					font-size: 10px;
					font-weight: 100;
					color: #333;
				}
			}
		}
	}
}
