@charset "UTF-8";
body {
	background: white;
	font-weight: normal;
	font-size: 14px;
	color: #333;
}

img {
	max-width: 100%;
	width: auto;
	transition: all 0.3s;
	&:hover {
		transition: all 0.3s;
	}
}

a {
	transition: all 0.3s;
	&:hover,
	&.active {
		text-decoration: none;
		transition: all 0.3s;
	}
}

input,
textarea,
select {
	&:focus {
		outline: none;
	}
}

ul {
	margin: 0;
	padding: 0;
}

.error-message {
	display: block;
	font-weight: 400;
	font-size: 14px;
	color: red;
}

#error-message-nhantin-email {
	display: block;
	font-weight: 400;
	font-size: 14px;
	color: red;
	position: absolute;
	bottom: -30px;
	left: 25px;

	@media (max-width: 767px) {
		left: 10px;
		bottom: -40px;
	}
}
