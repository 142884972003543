#footer {
	position: relative;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: top center;
	padding-top: 70px;
	z-index: 2;

	@media (min-width: 768px) {
		padding-top: 100px;
	}

	@media (min-width: 1200px) {
		padding-top: 150px;
	}

	&-logo {
		width: 200px;
		height: auto;
		margin-bottom: 36px;

		@media (max-width: 1199px) {
			margin-bottom: 24px;
		}

		@media (max-width: 767px) {
			margin-bottom: 16px;
		}

		img {
			width: 100%;
			height: 100%;
			object-fit: contain;
		}
	}

	&-social {
		position: relative;
		display: flex;
		flex-wrap: wrap;
		gap: 16px;

		@media (min-width: 1200px) {
			margin-left: 15px;
		}

		&--item {
			display: inline-block;
			background: #ffffff4d;
			border-radius: 100px;
			width: 31px;
			height: 31px;
			line-height: 31px;
			text-align: center;

			&:hover {
				background: $gradient-green;
			}

			img {
				width: 100%;
				height: 100%;
				object-fit: scale-down;
			}
		}
	}

	&-info {
		&--title {
			display: block;
			font-family: 'Arial';
			font-weight: 700;
			font-size: 18px;
			line-height: 1.6;
			color: #ffffff;
			margin-bottom: 15px;
		}

		&--list {
			li {
				position: relative;
				display: flex;
				align-items: flex-start;
				margin-bottom: 15px;

				img {
					display: inline-block;
					margin-top: 2px;
					margin-right: 10px;
				}

				span {
					flex: 1;
					display: inline-block;
					font-family: 'Lexend Deca';
					font-weight: 300;
					font-size: 14px;
					line-height: 24px;
					color: #ffffff;
				}
			}
		}
	}

	&-copyright {
		border-top: 1px dashed white;
		padding: 8px 0;

		span {
			display: block;
			font-family: 'Lexend Deca';
			font-style: normal;
			font-weight: 400;
			font-size: 11px;
			line-height: 29px;
			letter-spacing: 0.08em;
			color: #ffffff;
		}
	}
}

#fixedScreen {
	position: fixed;
	right: 20px;
	bottom: 20px;
	z-index: 111;
	display: flex;
	flex-flow: column;
	align-items: center;
	justify-content: center;

	.list-interval {
		display: flex;
		align-items: center;
		transition: all 1s ease-in-out;

		img {
			display: none;

			&.active {
				display: flex;
			}
		}
	}

	.item {
		display: block;
		width: 45px;
		height: 45px;
		line-height: 45px;
		text-align: center;
		background: linear-gradient(180deg, #cedd0e 0%, #008c30 100%);
		box-shadow: 0px 4.71859px 4.71859px rgba(0, 0, 0, 0.25);
		border: 2px solid #ffffff;
		border-radius: 50%;
		overflow: hidden;

		&:not(:last-child) {
			margin-bottom: 15px;
		}

		&:first-child {
			&.js-item-animate {
				.animated {
					&-current {
						transform: translateX(-100%);
						transition: all 0.3s;
					}

					&-next {
						transform: translateX(-100%);
						transition: all 0.3s;
					}
				}
			}
		}

		img {
			flex-shrink: 0;
			width: 100%;
			height: 100%;
			object-fit: contain;
			padding: 8px;
		}
	}

	.js-item-single {
		img {
			// &:not(:first-child) {
			// 	display: none;
			// }
		}
	}
}

#fixedSocialList {
	position: absolute;
	bottom: 70%;
	right: 50%;
	min-width: 210px;
	width: max-content;
	background: #ffffff;
	box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
	border-radius: 10px;
	opacity: 0;
	visibility: hidden;
	pointer-events: none;
	transition: all 0.3s;

	&.active {
		opacity: 1;
		visibility: visible;
		pointer-events: inherit;
		bottom: 100%;
		transition: all 0.3s;
	}

	.itemsc {
		position: relative;
		display: flex;
		align-items: center;
		padding: 15px;
		border-bottom: 1px solid #e9e9e9;

		&:hover {
			.textbox {
				span:last-child {
					color: $green;
				}
			}
		}

		.icon {
			flex-shrink: 0;
			width: 40px;
			height: 40px;

			img {
				width: 100%;
				height: 100%;
				object-fit: contain;
			}
		}

		.textbox {
			span {
				display: block;
				font-family: 'Lexend Deca';
				margin-left: 15px;

				&:first-child {
					font-weight: 400;
					font-size: 12px;
					line-height: 15px;
					color: #333333;
				}

				&:last-child {
					font-weight: 600;
					font-size: 16px;
					line-height: 20px;
					color: #333333;
				}
			}
		}
	}
}
