#banner {
	&-item {
		@include col-img(38%, cover);
		position: relative;

		@media (max-width: 767px) {
			@include col-img(45%, cover);
		}

		&::before,
		&::after {
			content: none;
			position: absolute;
			z-index: 1;
		}

		&::before {
			width: 100%;
			background-image: url(../images/banner-rectangle-wave-white.svg);
			background-repeat: no-repeat;
			background-size: 100% auto;
			bottom: 0;
			left: 0;
			right: 0;
			height: 32px;

			@media (min-width: 768px) {
				height: 65px;
			}

			@media (min-width: 992px) {
				height: 85px;
			}

			@media (min-width: 1200px) {
				height: 103px;
			}

			@media (min-width: 1366px) {
				height: 118px;
			}

			@media (min-width: 1600px) {
				height: 138px;
			}

			@media (min-width: 1636px) {
				height: 160px;
			}
		}

		&::after {
			width: 81%;
			background-image: url(../images/banner-rectangle-wave-green.svg);
			background-repeat: no-repeat;
			background-size: 100% auto;
			bottom: 0;
			right: 0;
			height: 32px;

			@media (min-width: 768px) {
				height: 65px;
			}

			@media (min-width: 992px) {
				height: 85px;
			}

			@media (min-width: 1200px) {
				height: 104px;
			}

			@media (min-width: 1366px) {
				height: 118px;
			}

			@media (min-width: 1600px) {
				height: 138px;
			}

			@media (min-width: 1636px) {
				height: 161px;
			}
		}
	}
}

#formTuvan {
	label {
		display: block;
		user-select: none;
		cursor: text;

		&::after {
			content: '*';
			position: relative;
			right: 0;
			display: inline-block;
			color: #eb5757;
		}
	}

	input {
		&:focus + label,
		&:valid + label,
		&:disabled + label {
			display: none;
		}

		&::-webkit-outer-spin-button,
		&::-webkit-inner-spin-button {
			-webkit-appearance: none;
			margin: 0;
		}

		&[type='number'] {
			-moz-appearance: textfield;
		}

		&::placeholder {
			color: #828282;
			opacity: 1;
		}

		&:-ms-input-placeholder {
			color: #828282;
			opacity: 1;
		}

		&::-ms-input-placeholder {
			color: #828282;
			opacity: 1;
		}
	}

	textarea {
		&::placeholder {
			color: #828282;
			opacity: 1;
		}

		&:-ms-input-placeholder {
			color: #828282;
			opacity: 1;
		}

		&::-ms-input-placeholder {
			color: #828282;
			opacity: 1;
		}
	}

	.submit {
		display: block;
		font-family: 'Inter';
		font-style: normal;
		font-weight: 700;
		font-size: 18px;
		line-height: 1;
		text-align: center;
		text-transform: uppercase;
		color: $green;
		max-width: 410px;
		width: 100%;
		margin: 0 auto;
		background: linear-gradient(
					to right,
					#e5c35c 0%,
					#f9df7b 38.02%,
					#fff3a6 67.19%,
					#f9df7b 99.74%
				)
				padding-box,
			linear-gradient(
					to right,
					#f9df7b 0%,
					#fff3a6 16.26%,
					#f9df7b 27.49%,
					#e5c35c 56.75%
				)
				border-box;
		border: 3px solid transparent;
		border-radius: 200px;
		padding: 16px;

		@media (min-width: 768px) {
			outline: 1px solid $green;
			outline-offset: -3px;
		}

		@media (max-width: 767px) {
			max-width: max-content;
			font-size: 15px;
			padding: 10px 30px;
		}

		&:hover {
			background: linear-gradient(
						to right,
						#f9df7b 0%,
						#fff3a6 16.26%,
						#f9df7b 27.49%,
						#e5c35c 56.75%
					)
					padding-box,
				linear-gradient(
						to right,
						#e5c35c 0%,
						#f9df7b 38.02%,
						#fff3a6 67.19%,
						#f9df7b 99.74%
					)
					border-box;
		}
	}
}

#section-home-4 {
	z-index: 1;

	&::before {
		content: '';
		position: absolute;
		width: 60%;
		height: 100%;
		background-image: url(../images/wave-section-4.png);
		background-repeat: no-repeat;
		background-size: 100% auto;
		top: 0;
		right: 0;
		z-index: -1;

		@media (max-width: 991px) {
			width: 100%;
		}

		@media (min-width: 1366px) {
			right: 60px;
		}
	}
}

#productMore {
	display: block;
	font-family: 'Inter';
	font-weight: 600;
	font-size: 16px;
	line-height: 19px;
	text-align: center;
	color: $green;
	max-width: 190px;
	width: 100%;
	background: linear-gradient(
				to right,
				#e5c35c 0%,
				#f9df7b 38.02%,
				#fff3a6 67.19%,
				#f9df7b 99.74%
			)
			padding-box,
		linear-gradient(
				to right,
				#f9df7b 0%,
				#fff3a6 16.26%,
				#f9df7b 27.49%,
				#e5c35c 56.75%
			)
			border-box;
	border: 3px solid transparent;
	border-radius: 200px;
	padding: 8px;

	@media (min-width: 768px) {
		outline: 1px solid $green;
		outline-offset: -3px;
	}

	@media (max-width: 767px) {
		max-width: max-content;
		font-size: 15px;
		padding: 10px 30px;
	}

	&:hover {
		background: linear-gradient(
					to right,
					#f9df7b 0%,
					#fff3a6 16.26%,
					#f9df7b 27.49%,
					#e5c35c 56.75%
				)
				padding-box,
			linear-gradient(
					to right,
					#e5c35c 0%,
					#f9df7b 38.02%,
					#fff3a6 67.19%,
					#f9df7b 99.74%
				)
				border-box;
	}
}

#productHomeSwiper {
	height: 100%;

	.swiper {
		display: none;

		&-initialized {
			display: block;
		}
	}

	.item {
		display: flex;
		flex-flow: column;
		height: 100%;
	}

	.swiper-slide {
		height: auto;
	}

	[class*='swiper-button'] {
		&::after {
			content: none;
		}

		width: 48px;
		height: 48px;
		top: 35%;

		@media (max-width: 991px) {
			transform: scale(0.8);
		}

		@media (min-width: 992px) {
			&.swiper-button-next {
				right: 25px;
			}

			&.swiper-button-prev {
				left: 25px;
			}
		}

		@media (min-width: 1366px) {
			top: 36%;

			&.swiper-button-next {
				right: -58px;
			}

			&.swiper-button-prev {
				left: -58px;
			}
		}

		@media (min-width: 1536px) {
			top: 50%;
		}
	}
}

#certificateSwiper {
	.swiper {
		display: none;
		&-initialized {
			display: block;
		}
	}

	[class*='swiper-button'] {
		&::after {
			content: none;
		}

		width: 48px;
		height: 48px;

		@media (min-width: 1366px) {
			&.swiper-button-next {
				right: -30px;
			}

			&.swiper-button-prev {
				left: -30px;
			}
		}

		@media (min-width: 1440px) {
			&.swiper-button-next {
				right: -76px;
			}

			&.swiper-button-prev {
				left: -76px;
			}
		}
	}

	.swiper-slide {
		@media (min-width: 992px) {
			.item {
				transform: scale(0.73);
				transition: all 0.3s;
			}

			&-active {
				.item {
					transform: scale(1);
					transition: all 0.3s;
				}
			}
		}
	}

	.item {
		position: relative;
		@include col-img(135%, contain);

		img {
			width: 80%;
			object-position: left;
		}

		&::before {
			content: '';
			position: absolute;
			background: url(../images/khung.png);
			background-repeat: no-repeat;
			background-size: 100% 100%;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
			z-index: 1;
			pointer-events: none;
		}
	}
}

#formDatlich {
	#formTuvan {
		.submit {
			background: linear-gradient(90deg, #5ca20a 0.29%, #0c6f39 99.88%)
					padding-box,
				linear-gradient(
						90deg,
						#f9df7b 0%,
						#fff3a6 16.26%,
						#f9df7b 27.49%,
						#e5c35c 56.75%
					)
					border-box;
			color: #fff;
			border: 2px solid transparent;
			outline: unset;

			&:hover {
				background: linear-gradient(-90deg, #5ca20a 0.29%, #0c6f39 99.88%)
						padding-box,
					linear-gradient(
							90deg,
							#f9df7b 0%,
							#fff3a6 16.26%,
							#f9df7b 27.49%,
							#e5c35c 56.75%
						)
						border-box;
			}
		}
	}
}

#form-nhantin {
	button {
		background: linear-gradient(to right, #5ca20a 0.29%, #0c6f39 99.88%)
				padding-box,
			linear-gradient(
					to right,
					#e5c35c 0%,
					#f9df7b 38.02%,
					#fff3a6 67.19%,
					#f9df7b 99.74%
				)
				border-box;
		box-shadow: 0px 0.3px 0.9px rgba(0, 0, 0, 0.1),
			0px 1.6px 3.6px rgba(0, 0, 0, 0.13);
		border: 1px solid transparent;
		border-radius: 24px;
		padding: 10px 40px;

		@media (max-width: 767px) {
			padding: 8px 18px;
		}

		&:hover {
			background: linear-gradient(to left, #5ca20a 0.29%, #0c6f39 99.88%)
					padding-box,
				linear-gradient(
						to right,
						#e5c35c 0%,
						#f9df7b 38.02%,
						#fff3a6 67.19%,
						#f9df7b 99.74%
					)
					border-box;
		}
	}
}

#timeline-list {
	li {
		position: relative;
		padding-left: 32px;

		@media (max-width: 767px) {
			padding-left: 26px;
		}

		&::before {
			content: '';
			position: absolute;
			left: 0;
			display: inline-block;
			width: 20px;
			height: 20px;
			background-image: url(../images/ic-checked.svg);
			background-repeat: no-repeat;
			background-size: 100% 100%;
		}
	}
}

#createSkew {
	position: relative;
	background: #008c30;
	height: 50px;
	width: 100%;
	border-radius: 14px;
	border-bottom-right-radius: 0;
	transform: skewY(-8deg);

	@media (min-width: 768px) {
		transform: skewY(-10deg);
	}

	.create-skew-number {
		position: absolute;
		bottom: 60%;
		left: 0;
		font-family: 'UTM Impact';
		font-weight: 400;
		font-size: 120px;
		line-height: 1;
		color: $green;
		transform: skewY(10deg);

		@media (max-width: 767px) {
			left: 5%;
		}
	}

	.create-skew-absolute {
		position: absolute;
		bottom: 100%;
		right: 0;
		font-family: 'Inter';
		font-weight: 700;
		font-size: 14px;
		line-height: 1.4;
		letter-spacing: -0.03em;
		color: $green;
		text-transform: uppercase;
	}
}

#beforeRadial {
	position: relative;
	z-index: 1;
	margin-top: -26px;

	&::before {
		content: '';
		position: absolute;
		bottom: -10px;
		left: 50%;
		height: 22px;
		background: radial-gradient(
			50% 50% at 50% 50%,
			#000000 0%,
			rgba(0, 0, 0, 0) 100%
		);
		mix-blend-mode: multiply;
		opacity: 0.6;
		z-index: -1;
		transform: translateX(-50%);
		width: 100%;

		@media (min-width: 768px) {
			width: 131%;
		}
	}

	&::after {
		content: '';
		position: absolute;
		background: $green;
		width: 100%;
		height: 100%;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		border-radius: 14px;
		z-index: -1;
		border-top-right-radius: 0;
	}
}

#timelineSwiper {
	max-width: 520px;
	margin: 0 auto;

	[class*='swiper-button'] {
		width: 55px;
		height: 55px;
		top: 25%;

		&::after {
			content: none;
		}

		transform: scale(0.7);

		@media (max-width: 767px) {
			display: none;
		}

		@media (min-width: 768px) {
			&.swiper-button-next {
				right: -60px;
			}

			&.swiper-button-prev {
				left: -60px;
			}
		}

		@media (min-width: 1200px) {
			transform: scale(1);

			&.swiper-button-next {
				right: -95px;
			}

			&.swiper-button-prev {
				left: -95px;
			}
		}
	}

	.swiper-slide {
		.item {
			font-family: 'Montserrat';
			font-weight: 700;
			font-size: 14px;
			letter-spacing: 0.03em;
			color: $green;
			width: 84px;
			height: 84px;
			line-height: 80px;
			text-align: center;
			background-image: url(../images/timeline-item.svg);
			background-repeat: no-repeat;
			background-size: 100% 100%;
			margin: 0 auto;
			transform: scale(0.8);
			transition: all 0.2s;
			cursor: pointer;
		}

		&-thumb-active {
			position: relative;

			&::before {
				content: '';
				position: absolute;
				max-width: 90px;
				width: 100%;
				height: 100%;
				background-image: url(../images/thumb-active.svg);
				background-repeat: no-repeat;
				background-size: 100% 100%;
				top: 100%;
				left: 50%;
				transform: translateX(-50%);

				@media (min-width: 1200px) {
					left: 53%;
				}
			}

			.item {
				position: relative;
				background-image: url(../images/timeline-item-active.svg);
				line-height: 84px;
				color: #c8120d;
				transform: scale(1);
				transition: all 0.2s;
			}
		}
	}
}

.breadcrumb {
	position: relative;
	z-index: 2;
	box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.1);
}

.shadows-right {
	@media (min-width: 768px) {
		position: relative;
		background: #ffffff;
		box-shadow: 10px 4px 20px rgba(0, 0, 0, 0.05);
		z-index: 1;

		&::before {
			content: '';
			position: absolute;
			background: linear-gradient(
				to bottom,
				rgba(255, 255, 255, 0) 0%,
				#ffffff 100%
			);
			width: 100%;
			height: 115px;
			bottom: -20px;
			left: 0;
			right: 0;
			z-index: -1;
		}
	}
}

#shadowChildren {
	box-shadow: 0px 4.52204px 27.1322px rgba(0, 0, 0, 0.12);
}

#list-makesConsultation {
	.item {
		&:not(:last-child) {
			margin-bottom: 25px;
		}

		&:hover,
		&.active {
			button {
				background: linear-gradient(270.16deg, #cedd0e 0.1%, #008c30 99.86%);

				img {
					filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(82deg)
						brightness(200%) contrast(105%);
					transition: unset;
				}

				span {
					color: #fff;
				}
			}
		}

		button {
			position: relative;
			display: flex;
			align-items: center;
			width: 100%;
			background: #ffffff;
			box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
			border-radius: 4px;
			border: 2px solid white;
			padding: 9px 15px;

			@media (min-width: 992px) {
				padding: 9px 25px;
			}

			img {
				flex-shrink: 0;
				margin-right: 10px;
				transition: unset;

				@media (min-width: 992px) {
					margin-right: 25px;
				}
			}

			span {
				display: block;
				font-family: 'Lexend Deca';
				font-weight: 400;
				font-size: 16px;
				line-height: 1.8;
				color: $green;
				text-transform: capitalize;

				@media (min-width: 992px) {
					font-size: 22px;
				}
			}
		}
	}
}

.modal {
	#formDatlich {
		position: relative;
	}

	#close {
		position: absolute;
		top: 10px;
		right: 10px;

		@media (min-width: 768px) {
			top: -16px;
			right: -16px;
		}
	}

	#formTuvan .submit {
		padding: 12px;

		@media (max-width: 767px) {
			max-width: 100%;
			width: 100%;
		}
	}
}
