article {
	display: flex;
	flex-flow: column;
	height: 100%;
}

#linkDirect {
	.item {
		position: relative;

		&::before {
			content: '';
			position: absolute;
			background: transparent;
			height: 2px;
			width: 0;
			top: 100%;
			left: 50%;
			transform: translateX(-50%);
			transition: all 0.3s;
		}

		&:hover,
		&.active {
			font-family: 'Lexend Deca';
			font-weight: 300;
			color: $green;

			&::before {
				background: currentColor;
				width: 75%;
				transition: all 0.3s;
			}
		}
	}
}

#gradient-shadow {
	position: relative;
	z-index: 1;

	@media (min-width: 992px) {
		&::before,
		&::after {
			content: '';
			position: absolute;
			background-repeat: no-repeat;
			width: 100%;
			height: 300px;
			left: 0;
			right: 0;
			z-index: -1;
		}

		&::before {
			background: linear-gradient(0, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
			top: -15px;
		}

		&::after {
			background: linear-gradient(
				180deg,
				rgba(255, 255, 255, 0) 0%,
				#ffffff 100%
			);
			bottom: -20px;
		}
	}
}

#posterAds {
	.swiper {
		&-pagination {
			position: unset;
			margin-top: 20px;

			@media (max-width: 1199px) {
				margin-top: 10px;
			}

			&-bullet {
				width: 6px;
				height: 6px;
				opacity: 1;
				background: #8c8c8c99;
				margin: 0 4px !important;

				&-active {
					background: #595959;
				}
			}
		}
	}
}

#blogDetail {
	& > * {
		font-family: 'Lexend Deca';
		font-weight: 300;
		font-size: 16px;
		line-height: 24px;
		color: #000000;
		margin-bottom: 15px;
	}
}
