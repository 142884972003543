.paginate {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
	gap: 10px;

	.item {
		display: inline-block;
		width: 30px;
		height: 30px;
		line-height: 29px;
		text-align: center;
		font-family: 'Inter';
		font-weight: 400;
		font-size: 14px;
		color: #333333;
		background: #ffffff;
		border: 1px solid #bdbdbd;
		border-radius: 3px;
		transition: all 0.2s;

		&:hover,
		&.active {
			color: #fff;
			background: $green;
			border: 1px solid $green;
			transition: all 0.2s;

			i {
				color: #fff;
			}
		}
	}
}
